import { required } from 'vuelidate/lib/validators';
import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'addEditComponentGroup',
  components: { DatePicker },
  props: ['componentGroupData'],
  data() {
    return {
      showValueSetModal: false,
      parent_value_set_id: null,
      unsubscribe: null,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      loader: false,
      groupId: 0,
      groupCode: null,
      groupName: null,
      groupDisplayName: null,
      startDate: null,
      endDate: null,
      active: true
    };
  },
  validations: {
    groupCode: { required },
    groupName: { required }
  },
  mounted() {
    if (this.componentGroupData) {
      this.getCompGroupDetails();
      // this.getComponentGroupListById(this.componentGroupData.group_id);
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          this.addEditComponentGroup();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        // if (actionName === 'upload') {
        //   this.eventBus.$emit('commonUpload', { id: this.compForm.group_id });
        // }
      }
    });
  },
  methods: {
    getCompGroupDetails() {
      this.groupId = this.componentGroupData.group_id;
      this.groupCode = this.componentGroupData.group_code;
      this.groupName = this.componentGroupData.group_name;
      this.groupDisplayName = this.componentGroupData.group_display_name;
      this.startDate = this.componentGroupData.group_start_date;
      this.endDate = this.componentGroupData.group_end_date;
      this.active = this.componentGroupData.active;
    },
    // getComponentGroupListById(group_id) {
    //   this.loader = true;
    //   this.$store
    //     .dispatch('masterData/getComponentGroupListById', group_id)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         const result = resp.data.data;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    addEditComponentGroup() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          group_id: this.groupId,
          group_code: this.groupCode,
          group_name: this.groupName,
          group_display_name: this.groupDisplayName,
          group_start_date: this.startDate,
          group_end_date: this.endDate,
          active: this.active
        };
        this.loader = true;
        this.$store
          .dispatch('masterData/addEditComponentGroup', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateList');
              this.groupId = response.data.data.group_id;
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.startDate);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
